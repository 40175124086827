import { requestLoadDashboard, requestLoadCompassionDashboard } from "@/service/DashboardService";

const prefix = "dashboard";

export const DashboardGetters = {
  GET_NEWS_LIST: `${prefix}GetNewsList`,
  GET_BLOGS_LIST: `${prefix}GetBlogsList`,
  GET_LISTING_COUNTS: `${prefix}GetListingCounts`,
  GET_INVENTORY_COUNTS: `${prefix}GetInventoryCounts`,
  GET_SAMPLE_COUNTS: `${prefix}GetSampleCounts`,
  GET_STORE_LOADING: `${prefix}GetStoreLoading`
};
export const DashboardMutationTypes = {
  SET_NEWS_LIST: `${prefix}SetNewsList`,
  SET_BLOGS_LIST: `${prefix}SetBlogsList`,
  SET_LISTING_COUNTS: `${prefix}SetListingCounts`,
  SET_INVENTORY_COUNTS: `${prefix}SetInventoryCounts`,
  SET_SAMPLE_COUNTS: `${prefix}SetSampleCounts`,
  SET_LOADING: `${prefix}SetLoading`,
  SET_USER_COUNT: `${prefix}SetUserCount`
};
export const DashboardActionTypes = {
  LOAD_DASHBOARD: `${prefix}LoadDashboard`,
  LOAD_COMPASSION_DASHBOARD: `${prefix}LoadCompassionDashboard`
};

export default {
  state: {
    newsList: [],
    blogsList: [],
    listingCounts: [],
    inventoryCounts: [],
    sampleCounts: [],
    loading: false,
    userCount: 0
  },
  getters: {
    [DashboardGetters.GET_NEWS_LIST]: (state) => state.newsList,
    [DashboardGetters.GET_BLOGS_LIST]: (state) => state.blogsList,
    [DashboardGetters.GET_LISTING_COUNTS]: (state) => state.listingCounts,
    [DashboardGetters.GET_INVENTORY_COUNTS]: (state) => state.inventoryCounts,
    [DashboardGetters.GET_SAMPLE_COUNTS]: (state) => state.sampleCounts,
    [DashboardGetters.GET_STORE_LOADING]: (state) => state.loading
  },
  mutations: {
    [DashboardMutationTypes.SET_USER_COUNT]: (state, value) => (state.userCount = value),
    [DashboardMutationTypes.SET_NEWS_LIST]: (state, value) => (state.newsList = value),
    [DashboardMutationTypes.SET_BLOGS_LIST]: (state, value) => (state.blogsList = value),
    [DashboardMutationTypes.SET_LISTING_COUNTS]: (state, value) => (state.listingCounts = value),
    [DashboardMutationTypes.SET_INVENTORY_COUNTS]: (state, value) => (state.inventoryCounts = value),
    [DashboardMutationTypes.SET_SAMPLE_COUNTS]: (state, value) => (state.sampleCounts = value),
    [DashboardMutationTypes.SET_LOADING]: (state, value) => (state.loading = value)
  },
  actions: {
    async [DashboardActionTypes.LOAD_COMPASSION_DASHBOARD]({ commit, getters }) {
      commit(DashboardMutationTypes.SET_LOADING, true);
      try {
        const { username, password } = getters.getAuthUserCredentials;
        const token = getters.getBearerToken;
        const { data } = await requestLoadCompassionDashboard(username, password, token);
        commit(DashboardMutationTypes.SET_USER_COUNT, data.admin.userCount);
      } catch (error) {
        //handle error
        console.log("error", error);
        //handleError("Error loading.", error);
      } finally {
        commit(DashboardMutationTypes.SET_LOADING, false);
      }
    },

    async [DashboardActionTypes.LOAD_DASHBOARD]({ commit, getters }) {
      commit(DashboardMutationTypes.SET_LOADING, true);
      try {
        const { username, password } = getters.getAuthUserCredentials;
        const token = getters.getBearerToken;
        const { data } = await requestLoadDashboard(username, password, token);
        commit(DashboardMutationTypes.SET_NEWS_LIST, data.admin.news);
        commit(DashboardMutationTypes.SET_BLOGS_LIST, data.admin.blogs);
        commit(DashboardMutationTypes.SET_LISTING_COUNTS, data.admin.listingCounts);
        commit(DashboardMutationTypes.SET_INVENTORY_COUNTS, data.admin.inventoryCounts);
        commit(DashboardMutationTypes.SET_SAMPLE_COUNTS, data.admin.sampleCounts);
      } catch (error) {
        //handle error
        console.log("error", error);
        //handleError("Error loading.", error);
      } finally {
        commit(DashboardMutationTypes.SET_LOADING, false);
      }
    }
  }
};
