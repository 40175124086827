export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read"
    }
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/pages/moderate-images/",
    name: "moderate-images",
    component: () => import("@/views/moderate-images/ModerateImages.vue"),
    meta: {
      pageTitle: "Moderate Images",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Moderate Images",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/moderate-images/image-moderation",
    name: "moderating-image",

    props: true,
    component: () => import("@/views/moderate-images/ModerateImagesApprove"),
    meta: {
      pageTitle: "Moderating Image",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Queue",
          to: "/pages/moderate-images"
        },
        {
          text: "Moderating Image",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/support/",
    name: "support",
    component: () => import("@/views/support/SupportRequest"),
    meta: {
      pageTitle: "Support",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Support",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/support/ticket-information",
    name: "support-conversation",
    props: true,
    component: () => import("@/views/support/SupportConversation"),
    meta: {
      pageTitle: "Ticket Information",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Support",
          to: "/pages/support"
        },
        {
          text: "Ticket Information",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/user/",
    name: "user",
    component: () => import("@/views/users/AllUsers"),
    meta: {
      pageTitle: "Users",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Users",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/contacts/queue",
    name: "contactQueue",
    component: () => import("@/views/users/QueuedContacts"),
    meta: {
      pageTitle: "VR Experience Queue",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "VR Queue",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/user/user-timeline/",
    name: "user-timeline",
    component: () => import("@/views/users/UserTimeline"),
    props: true,
    meta: {
      pageTitle: "User Timeline",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Users",
          to: "/pages/user"
        },
        {
          text: "User Timeline",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/user/create-user",
    name: "create-user",
    props: true,
    component: () => import("@/views/users/CreateUser"),
    meta: {
      pageTitle: "Create User",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Users",
          to: "/pages/user"
        },
        {
          text: "Create/Edit User",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/facilities/",
    name: "facilities",
    component: () => import("@/views/facilities/AllFacilities"),
    meta: {
      pageTitle: "Facilities",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Facilities",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/listings/",
    name: "listings",
    props: true,
    component: () => import("@/views/listings/AllListings"),
    meta: {
      pageTitle: "Listings",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Listings",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/samples/",
    name: "samples",
    props: true,
    component: () => import("@/views/samples/AllSamples"),
    meta: {
      pageTitle: "Samples",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Samples",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/inventory/",
    name: "inventory",
    component: () => import("@/views/inventory/AllInventory"),
    props: true,
    meta: {
      pageTitle: "Inventory",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Inventory",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/appWelcome/",
    name: "appWelcomeMessage",
    component: () => import("@/views/listoconfig/WelcomeMessage"),
    meta: {
      pageTitle: "Welcome Message",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Welcome Message",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/termsAndConditions/",
    name: "termsAndConditions",
    component: () => import("@/views/listoconfig/TermsAndConditions"),
    meta: {
      pageTitle: "Terms & Conditions",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Terms and Conditions",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/blogNewsViews/",
    name: "blogNewsViews",
    component: () => import("@/views/listoconfig/BlogNews"),
    meta: {
      pageTitle: "Blogs",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Blogs / News",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/organization/",
    name: "organization",
    component: () => import("@/views/organization/AllOrganizations"),
    meta: {
      pageTitle: "Organizations",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Organization",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/metrc/",
    name: "metrc",
    component: () => import("@/views/metrc/AllMetrc"),
    meta: {
      pageTitle: "Metrc Sync Status",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Metrc",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/authentication/login-v1",
    name: "auth-login-v1",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/pages/authentication/login-v2",
    name: "auth-login-v2",
    component: () => import("@/views/pages/authentication/Login-v2.vue"),
    meta: {
      layout: "full"
    }
  },

  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/pages/account-setting",
    name: "pages-account-setting",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      pageTitle: "Account Settings",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Account Settings",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      resource: "Listo",
      action: "read",
      breadcrumb: [
        {
          text: "Profile",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/compassionOnboarding/",
    name: "compassionOnboarding",
    component: () => import("@/views/users/Compassion/Onboarding"),
    meta: {
      pageTitle: "Onboard Patient",
      resource: "COperator",
      action: "read"
    }
  },
  {
    path: "/pages/compassionEvents/",
    name: "compassionEvents",
    component: () => import("@/views/users/Compassion/Events"),
    meta: {
      pageTitle: "Events",
      resource: "COperator",
      action: "read"
    }
  },
  {
    path: "/pages/compassionEvents/eventRegistration/",
    name: "eventRegistration",
    component: () => import("@/views/users/Compassion/EventRegistration"),
    meta: {
      pageTitle: "Event Registration",
      resource: "COperator",
      action: "read",
      breadcrumb: [
        {
          text: "Events",
          to: "/pages/compassionEvents/"
        },
        {
          text: "Event Registration",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/compassionDocumentCheck/",
    name: "compassionExpirationCheck",
    component: () => import("@/views/users/Compassion/ExpirationCheck"),
    meta: {
      pageTitle: "Check Document Expiration",
      resource: "COperator",
      action: "read"
    }
  },
  {
    path: "/pages/compassionCustomers/",
    name: "compassionCustomers",
    component: () => import("@/views/users/Compassion/Customers"),
    meta: {
      pageTitle: "Patients",
      resource: "Compassion",
      action: "read"
    }
  },
  {
    path: "/pages/compassionCustomers/details",
    name: "compassion-details-operator",
    props: true,
    component: () => import("@/views/users/Compassion/CustomerDetails"),
    meta: {
      pageTitle: "Details",
      resource: "COperator",
      action: "read",
      breadcrumb: [
        {
          text: "Expiration",
          to: "/pages/compassionDocumentCheck"
        },
        {
          text: "Details",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/compassionCustomers/details",
    name: "compassion-details",
    props: true,
    component: () => import("@/views/users/Compassion/CustomerDetails"),
    meta: {
      pageTitle: "Details",
      resource: "Compassion",
      action: "read",
      breadcrumb: [
        {
          text: "Patients",
          to: "/pages/compassionCustomers"
        },
        {
          text: "Details",
          active: true
        }
      ]
    }
  },
  {
    path: "/pages/compassionPartners/",
    name: "compassionPartners",
    component: () => import("@/views/users/Compassion/Partners"),
    meta: {
      pageTitle: "Partners",
      resource: "Compassion",
      action: "read"
    }
  }
];
