export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      resource: "Listo",
      action: "read"
    }
  },
  {
    path: "/compassion-dashboard",
    name: "compassion-dashboard",
    component: () => import("@/views/dashboard/compassion/Dashboard.vue"),
    meta: {
      resource: "Compassion",
      action: "read"
    }
  }
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
];
