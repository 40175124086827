import {setAxiosAuthToken} from "@/libs/axios";

export const prefix = 'auth'

export const AuthMutationTypes = {
    SET_AUTH_USER: `${prefix}SetAuthUser`,
    SET_USERNAME: `${prefix}SetUsername`,
    SET_PASSWORD: `${prefix}SetPassword`,
    SET_BEARER_TOKEN: `${prefix}SetBearerToken`,
    SET_LOGIN_SUCCESS: `${prefix}SetLoginSuccess`,
}
export const AuthActionTypes = {
    LOGIN_SUCCESS: `${prefix}LoginSuccess`,
    REMOVE_CREDENTIALS: `${prefix}RemoveCredentials`,
}

export default {
    //namespaced: true,
    state: {
        authUser: null,
        userName: null,
        userPass: null,
        bearerToken: null,
        loginSuccess: false
    },
    getters: {
        getAuthUser: state => state.authUser,
        getAuthUserCredentials: (state, getters) => ({
            userName: getters.getUserName,
            userPass: getters.getUserPass
        }),
        getUserName: state => state.userName,
        getUserPass: state => state.userPass,
        getBearerToken: state => state.bearerToken,
    },
    mutations: {
        [AuthMutationTypes.SET_AUTH_USER]: (state, value) => (state.authUser = value),
        [AuthMutationTypes.SET_USERNAME]: (state, value) => (state.userName = value),
        [AuthMutationTypes.SET_PASSWORD]: (state, value) => (state.userPass = value),
        [AuthMutationTypes.SET_BEARER_TOKEN]: (state, value) => (state.bearerToken = value),
        [AuthMutationTypes.SET_LOGIN_SUCCESS]: (state, value) => (state.loginSuccess = value)
    },
    actions: {
        async [AuthActionTypes.LOGIN_SUCCESS]({ commit, getters }, credentials) {
            console.log('entering login success from store', credentials);
            commit(AuthMutationTypes.SET_AUTH_USER, credentials.authUser);
            commit(AuthMutationTypes.SET_USERNAME, credentials.authUser.username);
            commit(AuthMutationTypes.SET_PASSWORD, credentials.userPass);
            commit(AuthMutationTypes.SET_LOGIN_SUCCESS, true);
            commit(AuthMutationTypes.SET_BEARER_TOKEN, credentials.bearerToken);
            setAxiosAuthToken(credentials.bearerToken);
        },
        async [AuthActionTypes.REMOVE_CREDENTIALS]({ commit, getters }) {
            commit(AuthMutationTypes.SET_AUTH_USER, null);
            commit(AuthMutationTypes.SET_USERNAME, null);
            commit(AuthMutationTypes.SET_PASSWORD, null);
            commit(AuthMutationTypes.SET_LOGIN_SUCCESS, false);
            commit(AuthMutationTypes.SET_BEARER_TOKEN, null);
            setAxiosAuthToken(null);
        }
    },
}
