import Vue from "vue";

// axios
import axios from "axios";

//increase default timeout to 30 mins
axios.defaults.timeout = 1800000;
//axios.defaults.withCredentials = true;

//axios.defaults.xsrfCookieName = "XSRF-TOKEN";
//axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: `/api`,
  headers: { "x-app-id": "listo-admin" }
});

export const axiosRoot = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: `/`,
  headers: { "x-app-id": "listo-admin" }
});

const hn = window.location.hostname;
const tn = hn + "-deviceId";
let devId = localStorage.getItem(tn);
console.log(tn, devId);
if (devId) {
  axiosIns.defaults.headers.common["x-device-id"] = devId;
  axiosRoot.defaults.headers.common["x-device-id"] = devId;
}
axiosIns.defaults.headers.common["x-screen-size"] = "(" + window.innerWidth + ", " + window.innerHeight + ")";
axiosIns.defaults.headers.common["x-device-type"] = "Desktop";

// Required for retrying functionality
axiosIns.defaults.retryConfig = {
  instance: axiosIns
};

Vue.prototype.$http = axiosIns;

export const setAxiosAuthToken = (token) => (axiosIns.defaults.headers.common["Authorization"] = `Bearer ${token}`);

export default axiosIns;
