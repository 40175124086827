// Generates Store Persistance plugin configuration.

import createPersistedState from "vuex-persistedstate";
import {setAxiosAuthToken} from "@/libs/axios";
import {securels} from "@/store/store-persisted-state/securels";
export const storePersistedState = createPersistedState({
    key: "vuex",
    paths: ["auth"],
    storage: {
        getItem: (key) => {
            try {
                return securels.get(key);
            } catch (err) {
                // Ignore parsing error
                console.log('Error parsing localStorage:', err);
                return undefined;
            }
        },
        setItem: (key, value) => securels.set(key, value),
        removeItem: (key) => securels.remove(key),
    },
    rehydrated: store => {
        const { state } = store;
        if(state.auth && state.auth.bearerToken) {
            setAxiosAuthToken(state.auth.bearerToken)
        }
    }
});
