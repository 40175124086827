import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import apps from "./routes/apps";
import dashboard from "./routes/dashboard";
import pages from "./routes/pages";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard" } },
    ...apps,
    ...dashboard,
    ...pages,
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.beforeEach((to, _, next) => {
  //extra: setting page title if set
  if (to.params.title) {
    to.meta.pageTitle = to.params.title;
  }
  if (to.path === "/dashboard") {
    const userData = getUserData();
    if (userData && userData.compassionAdmin && !userData.admin) {
      return next({ name: "compassion-dashboard" });
    }
  }

  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: "auth-login" });
    }

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.admin : false));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
