import Vue from "vue";
import Vuex from "vuex";

// Modules
import { storePersistedState } from "@/store/store-persisted-state";
import app from "./app";
import appConfig from "./app-config";
import auth from "./auth";
import dashboard from "./dashboard";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

const vuexStore = new Vuex.Store({
  plugins: [storePersistedState],
  modules: {
    app,
    appConfig,
    auth,
    Dashboard: dashboard,
    verticalMenu
  }
  // strict: process.env.DEV,
});

export default vuexStore;
